import {Controller} from "stimulus"
import $ from 'jquery';


export default class extends Controller {
    connect() {
        // alert('Estamos en billing');
    }

    open_tree(e) {
        let toggler_a = document.getElementsByClassName("caret-bl");
        let i;

        for (i = 0; i < toggler_a.length; i++) {
            e.target.parentElement.querySelector(".nested-bl").classList.toggle("active-bl");
            e.target.classList.toggle("caret-down-bl");
        }
    }

    validate_emails(e) {
        let value = $.trim(e.target.value);

        let emails = value.split(',').map((email) => {
            return $.trim(email);
        }).filter((email) => {
            return email !== '';
        });

        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        let helper_email_error = [];
        let isValid_emails = [];
        emails.map(email => {
            if (emailRegex.test(email)) {
                isValid_emails.push(true);
            } else {
                helper_email_error.push(email);
                isValid_emails.push(false);
            }
        })
        /*let isValid = emails.every(function (email) {
            if (emailRegex.test(email)) {
                return true;
            } else {
                helper_email_error.push(email);
                return false;
            }

        });*/

        let isValid = isValid_emails.every(valor => {
            return valor === true;
        })

        if (!isValid) {
            $(`#${e.target.dataset.validate}`).attr('disabled', 'disabled');
            e.target.classList.add('is-invalid');
            $(`#${e.target.dataset.helper}`).text(`Correos que no cumplen con los requisitos: ${helper_email_error.join(', ')}`)
        } else {
            $(`#${e.target.dataset.validate}`).removeAttr('disabled');
            e.target.classList.remove('is-invalid');
            $(`#${e.target.dataset.helper}`).text('')
            helper_email_error = []
            // console.log(e.target);
        }
    }

    validate_date(e) {
        let value = $.trim(e.target.value);

        if (value === '') {
            $(`#${e.target.dataset.validate}`).attr('disabled', 'disabled')
        } else {
            $(`#${e.target.dataset.validate}`).removeAttr('disabled')
        }
    }
}