import {Controller} from "stimulus"
import $ from 'jquery';
import bootbox from 'bootbox/bootbox';
import toastr from 'toastr/toastr'
import 'toastr/toastr.scss'
import 'bootstrap-table/dist/bootstrap-table.min.css'
import 'bootstrap-table/dist/bootstrap-table.min'
import 'bootstrap-table/dist/locale/bootstrap-table-es-ES.min'
import {obtenerIdCheckDatatable} from "./admin_controller";


export default class extends Controller {

    connect() {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": true,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "3000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }

        let table = $('#table');
        let admin_table = $('#admin_table');
        let revision_table = $('#revision_table');
        let revision_history_table = $('#revision_history_table');
        table.bootstrapTable({
            language: 'es',
            pagination: true,
            search: true,
            refresh: true,
            sidePagination: "server",
            showRefresh: "true",
            pageSize: 10,
            pageList: "[10, 25, 50, 100, 200, All]",
            toolbar: '#toolbar',
            toolbarAlign: "left",
            // idField: "id",
            // selectItemName: "id",
            onLoadSuccess: function (data) {
                table.bootstrapTable('checkInvert');
            }

        });
        admin_table.bootstrapTable({
            language: 'es',
            pagination: true,
            search: false,
            refresh: true,
            sidePagination: "server",
            showRefresh: "true",
            pageSize: 5,
            pageList: "[5, 25, 50, 100, 200, All]",
            toolbar: '#toolbar',
            toolbarAlign: "left",
            idField: "id",
            selectItemName: "id",
            onLoadSuccess: function (data) {
                table.bootstrapTable('checkInvert');
            }

        });
        revision_table.bootstrapTable({
            language: 'es',
            pagination: true,
            search: true,
            refresh: true,
            sidePagination: "server",
            showRefresh: "true",
            pageSize: 5,
            pageList: "[5, 25, 50, 100, 200, All]",
            toolbar: '#toolbar',
            toolbarAlign: "left",
            idField: "id",
            selectItemName: "id",
            onLoadSuccess: function (data) {
                table.bootstrapTable('checkInvert');
            }

        });
        revision_history_table.bootstrapTable({
            language: 'es',
            pagination: true,
            search: true,
            refresh: true,
            sidePagination: "server",
            showRefresh: "true",
            pageSize: 5,
            pageList: "[5, 25, 50, 100, 200, All]",
            toolbar: '#toolbar',
            toolbarAlign: "left",
            idField: "id",
            selectItemName: "id",
            onLoadSuccess: function (data) {
                table.bootstrapTable('checkInvert');
            }
        });

        $('#table_client').bootstrapTable();
        $('#table_client_two').bootstrapTable();
        // $('table_client').bootstrapTable();

        $('#checkBtn').on('change', function () {
            $('.inputBtnDataTable').prop('checked', $(this).is(':checked'));
        })


    }


    edit(e){
       let ids = obtenerIdCheckDatatable();
       if(ids.length < 1){
           toastr.info('Debe seleccionar la menos un elemento para continuar con esta acción','Importante')
       }else{
           location.href = `${location.origin}${e.target.dataset.url}?targets=${ids}`
       }

    }
    delete(e) {
        bootbox.confirm({
            message: "¿Seguro que desea eliminar este elemento? <br> <p style='font-size: .7em;' class='text-danger'>Tenga en cuenta que este proceso es inreversible.</p>",
            buttons: {
                confirm: {
                    label: '<i class="fa fa-check"></i> Aceptar',
                    className: 'btn-success'
                },
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancelar',
                    className: 'btn-secondary'
                }
            },
            size: 'small',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: e.target.dataset.target + '/delete',
                        data: {
                            format: 'json'
                        },
                        type: 'GET',
                        success: function (data) {
                            if (data.success) {
                                toastr.success(data.msg);
                                $('#table').bootstrapTable('refresh');
                                $('#table_client').bootstrapTable('refresh');
                            } else {
                                toastr.error(data.msg);
                                // console.log(data.msg);
                            }
                        }
                    })
                }
            }
        })

    }

    deleteBlock(e) {
        let ids = obtenerIdCheckDatatable();
        if(ids.length < 1){
            toastr.info('Debe seleccionar al menos un elemento para continuar con esta acción','Importante')
        }else{
            bootbox.confirm({
                message: `¿Seguro que desea eliminar estos ${ids.length} elementos? <br> <p style='font-size: .7em;' class='text-danger'>Tenga en cuenta que este proceso es inreversible.</p>`,
                buttons: {
                    confirm: {
                        label: '<i class="fa fa-check"></i> Aceptar',
                        className: 'btn-success'
                    },
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn-secondary'
                    }
                },
                size: 'small',
                callback: function (result) {
                    if (result) {
                        $.ajax({
                            url: e.target.dataset.url,
                            data: {
                                format: 'json',
                                id: ids
                            },
                            type: 'GET',
                            success: function (data) {
                                if (data.success) {
                                    toastr.success(data.msg);
                                    $('#table').bootstrapTable('refresh');
                                    $('#table_client').bootstrapTable('refresh');
                                } else {
                                    toastr.error(data.msg);
                                    // console.log(data.msg);
                                }
                            }
                        })
                    }
                }
            })
        }


    }

    disabled(e) {
        bootbox.confirm({
            message: "¿Seguro que desea habilitar/deshabilitar este elemento?",
            buttons: {
                confirm: {
                    label: '<i class="fa fa-check"></i> Aceptar',
                    className: 'btn-success'
                },
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancelar',
                    className: 'btn-secondary'
                }
            },
            size: 'small',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: e.target.dataset.target + '/disabled',
                        data: {
                            format: 'json'
                        },
                        type: 'GET',
                        success: function (data) {
                            if (data.success) {
                                toastr.success(data.msg);
                                $('#table').bootstrapTable('refresh');
                                $('#table_client').bootstrapTable('refresh');
                            } else {
                                toastr.error(data.msg);
                                // console.log(data.msg);
                            }
                        }
                    })
                }
            }
        })

    }

    block(e) {
        bootbox.confirm({
            message: "¿Seguro que desea bloquear el acceso y la renta a este usuario?",
            buttons: {
                confirm: {
                    label: '<i class="fa fa-check"></i> Aceptar',
                    className: 'btn-success'
                },
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancelar',
                    className: 'btn-secondary'
                }
            },
            size: 'small',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: e.target.dataset.target + '/block',
                        data: {
                            format: 'json'
                        },
                        type: 'GET',
                        success: function (data) {
                            if (data.success) {
                                toastr.success(data.msg);
                                $('#table').bootstrapTable('refresh');
                            } else {
                                toastr.error(data.msg);
                                // console.log(data.msg);
                            }
                        }
                    })
                }
            }
        })

    }

    change_status(e) {

        bootbox.confirm({
            message: "¿Seguro que desea cambiar el estado de disponibilidad?",
            buttons: {
                confirm: {
                    label: '<i class="fa fa-check"></i> Aceptar',
                    className: 'btn-success'
                },
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancelar',
                    className: 'btn-secondary'
                }
            },
            size: 'small',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: e.target.dataset.target,
                        data: {
                            format: 'json'
                        },
                        type: 'GET',
                        success: function (data) {
                            if (data.success) {
                                toastr.success(data.msg);
                                $('#table').bootstrapTable('refresh');
                            } else {
                                toastr.error(data.msg);
                                // console.log(data.msg);
                            }
                        }
                    })
                }
            }
        })

    }

    commercial(e) {
        // console.log(e);
        bootbox.confirm({
            message: "¿Seguro que desea poner este usuario como Comercial del sitio?",
            buttons: {
                confirm: {
                    label: '<i class="fa fa-check"></i> Aceptar',
                    className: 'btn-success'
                },
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancelar',
                    className: 'btn-secondary'
                }
            },
            size: 'small',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: e.target.dataset.target,
                        data: {
                            format: 'json'
                        },
                        type: 'POST',
                        success: function (data) {
                            if (data.success) {
                                toastr.success(data.msg);
                                $('#table').bootstrapTable('refresh');
                            } else {
                                toastr.error(data.msg);
                                // console.log(data.msg);
                            }
                        }
                    })
                }
            }
        })

    }

    filterTable(e) {
        $(`#${e.target.dataset.table}`).bootstrapTable('refresh');
    }

    clearFilter(e) {
        let parentElement = $(`#${e.target.dataset.target}`);
        let input = parentElement.find('input[type="text"]');
        let input_hidden = parentElement.find('input[type="hidden"]');
        let select = parentElement.find('select');

        input.map((index, elem) => {
           elem.value = '';
        })
        input_hidden.map((index, elem) => {
           elem.value = '';
        })
        select.map((index, elem) => {
            $(elem).val(null).trigger('change');
        })
    }
    checkUncheckAll(e){
        $('.inputBtnDataTable').prop('checked', $(e.target).is(':checked'));
    }

    mark_as_pay(e){

        if(e.target.dataset.voucher !== undefined && e.target.dataset.voucher === 'true'){
            let ids = obtenerIdCheckDatatable();
            let message = '¿Desea marcar como <b>"PAGADO"</b> todas las Reservas.?'
            if(ids.length > 0){
                message = `¿Desea marcar como <b>"PAGADO"</b> las (${ids.length}) Reservas seleccionadas.?`;

            }else{
                toastr.info('Debe seleccionar al menos 1 reserva para marcar como "PAGADO"', 'Información')
            }

            bootbox.confirm(message, (result) => {
                if(result){
                    $.ajax({
                        url: `${location.origin}/admin/histories/agencies/billing/mark`,
                        data: {
                            agency_billing: e.target.dataset.last,
                            vouchers: ids
                        },
                        type: 'GET',
                        success: (data)=>{
                            if(data.success){
                                location.reload();
                            }else{
                                toastr.error(data.message, data.code);
                            }
                        }
                    })
                }
            })
        }else{
            let ids = obtenerIdCheckDatatable(e.target.dataset.table);
            let message = '¿Desea marcar como <b>"PAGADO"</b> todas las Reservas de esta Facturación.?'
            if(ids.length > 0){
                message = `¿Desea marcar como <b>"PAGADO"</b> las (${ids.length}) Reservas seleccionadas de esta Facturación.?`;
            }
            bootbox.confirm(message, (result) => {
                if(result){
                    $.ajax({
                        url: `${location.origin}/admin/histories/agencies/billing/mark`,
                        data: {
                            agency_billing: e.target.dataset.last,
                            vouchers: ids
                        },
                        type: 'GET',
                        success: (data)=>{
                            if(data.success){
                                location.reload();
                            }else{
                                toastr.error(data.message, data.code);
                            }
                        }
                    })
                }
            })
        }


    }


}