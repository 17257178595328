import I18n from 'i18n-js';
I18n.translations || (I18n.translations = {});
I18n.translations["es"] = I18n.extend((I18n.translations["es"] || {}), {
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "La validación falló: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "No se puede eliminar el registro porque existen %{record} dependientes",
          "has_one": "No se puede eliminar el registro porque existe un %{record} dependiente"
        }
      }
    }
  },
  "check_reservation": {
    "code_text": "Chequear reserva",
    "no_search_params": "No se especificó ningún criterio de búsqueda",
    "reference_incorrect": "No hay ninguna reserva  con No. de referencia: %{reference}",
    "reference_not_found": "No pudimos validar ninguna reserva con No. de referencia: %{reference}, para el Email: %{email}"
  },
  "date": {
    "abbr_day_names": [
      "dom",
      "lun",
      "mar",
      "mié",
      "jue",
      "vie",
      "sáb"
    ],
    "abbr_month_names": [
      null,
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic"
    ],
    "day_names": [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado"
    ],
    "formats": {
      "default": "%-d/%-m/%Y",
      "long": "%-d de %B de %Y",
      "short": "%-d de %b"
    },
    "month_names": [
      null,
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre"
    ],
    "order": [
      "day",
      "month",
      "year"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "alrededor de 1 hora",
        "other": "alrededor de %{count} horas"
      },
      "about_x_months": {
        "one": "alrededor de 1 mes",
        "other": "alrededor de %{count} meses"
      },
      "about_x_years": {
        "one": "alrededor de 1 año",
        "other": "alrededor de %{count} años"
      },
      "almost_x_years": {
        "one": "casi 1 año",
        "other": "casi %{count} años"
      },
      "half_a_minute": "medio minuto",
      "less_than_x_minutes": {
        "one": "menos de 1 minuto",
        "other": "menos de %{count} minutos"
      },
      "less_than_x_seconds": {
        "one": "menos de 1 segundo",
        "other": "menos de %{count} segundos"
      },
      "over_x_years": {
        "one": "más de 1 año",
        "other": "más de %{count} años"
      },
      "x_days": {
        "one": "1 día",
        "other": "%{count} días"
      },
      "x_minutes": {
        "one": "1 minuto",
        "other": "%{count} minutos"
      },
      "x_months": {
        "one": "1 mes",
        "other": "%{count} meses"
      },
      "x_seconds": {
        "one": "1 segundo",
        "other": "%{count} segundos"
      },
      "x_years": {
        "one": "1 año",
        "other": "%{count} años"
      }
    },
    "prompts": {
      "day": "Día",
      "hour": "Hora",
      "minute": "Minuto",
      "month": "Mes",
      "second": "Segundo",
      "year": "Año"
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "debe ser aceptado",
      "blank": "no puede estar en blanco",
      "confirmation": "no coincide",
      "empty": "no puede estar vacío",
      "equal_to": "debe ser igual a %{count}",
      "even": "debe ser par",
      "exclusion": "está reservado",
      "greater_than": "debe ser mayor que %{count}",
      "greater_than_or_equal_to": "debe ser mayor que o igual a %{count}",
      "inclusion": "no está incluido en la lista",
      "invalid": "no es válido",
      "less_than": "debe ser menor que %{count}",
      "less_than_or_equal_to": "debe ser menor que o igual a %{count}",
      "model_invalid": "La validación falló: %{errors}",
      "not_a_number": "no es un número",
      "not_an_integer": "debe ser un entero",
      "odd": "debe ser impar",
      "other_than": "debe ser distinto de %{count}",
      "present": "debe estar en blanco",
      "required": "debe existir",
      "taken": "ya está en uso",
      "too_long": {
        "one": "es demasiado largo (1 carácter máximo)",
        "other": "es demasiado largo (%{count} caracteres máximo)"
      },
      "too_short": {
        "one": "es demasiado corto (1 carácter mínimo)",
        "other": "es demasiado corto (%{count} caracteres mínimo)"
      },
      "wrong_length": {
        "one": "no tiene la longitud correcta (1 carácter exactos)",
        "other": "no tiene la longitud correcta (%{count} caracteres exactos)"
      }
    },
    "template": {
      "body": "Se encontraron problemas con los siguientes campos:",
      "header": {
        "one": "No se pudo guardar este/a %{model} porque se encontró 1 error",
        "other": "No se pudo guardar este/a %{model} porque se encontraron %{count} errores"
      }
    }
  },
  "errors_page": {
    "internal_server_error": "al parecer hubo un error en el servidor, inténtelo más tarde",
    "locked": "Este recurso está bloqueado.",
    "not_accepted": "Hemos detectado que esta usando un servidor proxy para acceder a nuestra web",
    "not_found": "La página solicitada no existe o puede que el enlace este roto",
    "unprocessable_entity": "La acción no se pudo procesar correctamente debido a que se proporcionaron datos no válidos"
  },
  "global": {
    "admin": {
      "en": "Ingles",
      "es": "Español",
      "fr": "Frances",
      "ru": "Ruso"
    },
    "footer": {
      "faqs": "Preguntas frecuentes",
      "privacy_policy": "Política de Privacidad y Uso de Cookies",
      "term_and_conditions": "Términos y condiciones"
    },
    "in_maintenance": {
      "content": "En estos momentos estamos ejecutando acciones de mantenimiento. Estaremos en línea muy pronto.",
      "icon": ":(",
      "not_acceptable": "Petición no aceptada.",
      "title": "¡Lamentamos las molestias!"
    },
    "main": {
      "form": {
        "message": {
          "checking_availability": "Comprobando la disponibilidad del servicio solicitado...",
          "create_shooping_cart": "Validando datos de la renta...",
          "no_availability": "No hay disponibilidad para el servicio solicitado.",
          "search_modality": "Buscando modalidad de renta...",
          "search_tariff": "Buscando tarifa de renta..."
        },
        "select_category": "Seleccione una categoría",
        "select_destiny": "Seleccione un destino",
        "select_drivers": "Cantidad de conductores",
        "select_modality": "Seleccione una modalidad",
        "select_office": "Seleccione una oficina",
        "select_option": "Selecione una opción",
        "select_pack": "Seleccione un pack",
        "work_all_day": "Esta oficina trabaja 24 horas.",
        "work_by_hours": "Esta oficina trabaja en el horario de %{from} a %{to} "
      },
      "info": "Ver detalles",
      "read_more": "Leer más...",
      "view_more": "Ver más"
    },
    "online_asistan": "Asistencia en Línea",
    "policies": "Políticas",
    "popup": {
      "cancel_rent_cancel": "No, continuar con mi reserva",
      "cancel_rent_confirm": "Si, cancelar",
      "create_shopping_cart_confirm": "Entendido",
      "product_no_disponibility_confirm": "Entendido",
      "verify_email_confirm": "Validar",
      "verify_email_in_process_confirm": "Entendido",
      "verify_login_for_rent_cancel": "Cancelar",
      "verify_login_for_rent_cancel_confirm": "Entendido",
      "verify_login_for_rent_confirm": "Iniciar sesión",
      "verify_login_for_rent_denied": "Registrarme",
      "with_rent_cancel": "No, continuar con el antiguo",
      "with_rent_confirm": "Si, deseo continuar",
      "with_rent_in_pay_process_cancel": "No deseo continuar",
      "with_rent_in_pay_process_cancel_confirm": "Entendido",
      "with_rent_in_pay_process_confirm": "Si, deseo continuar",
      "with_rent_redirect_to_pay_confirm": "Si, deseo continuar",
      "with_rent_redirect_to_paycancel": "No, Cancelar la reserva",
      "without_rent_confirm": "Entendido"
    },
    "section_an_page": {
      "back": "Regresar",
      "back_to_home": "Regresar al inicio",
      "go_to_admin": "Ir a la administración",
      "print": "Imprimir"
    },
    "slide_to_show_more": "Puede deslizar el menú para ver más productos",
    "slogan": "Empresa de Transporte Turístico Transtur",
    "time": {
      "day": "Días",
      "hours": "Horas",
      "minutes": "Minutos",
      "seconds": "Segundos"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Por favor seleccione"
    },
    "submit": {
      "create": "Crear %{model}",
      "submit": "Guardar %{model}",
      "update": "Actualizar %{model}"
    }
  },
  "histories": {
    "check_reservation": {
      "address": "Dirección",
      "client": "Cliente",
      "create_at": "Creado",
      "days": "Días",
      "droppof_place": "Lugar de entrega",
      "email": "Correo electrónico",
      "from": "Desde",
      "name_last_name": "Nombre(s) y Apellidos",
      "notice": "Guarde su voucher y al ingresar al Shuttle o al Bus Tour muestre el QR de su reserva.",
      "pickup_place": "Lugar de recogida",
      "price": "Precio",
      "product": "Producto",
      "product_name": "Nombre",
      "quantity": "Cantidad",
      "reference_number": "No. Referencia",
      "reservation_status": "Estado de la reserva",
      "sign_invoice": "La factura se creó en una computadora y es válida sin la firma y el sello.",
      "status_cancel": "Cancelada",
      "status_confirmation": "Confirmada",
      "status_in_confirmation": "En confirmación",
      "status_modify": "Modificada",
      "thanks": "Gracias por usar nuestros servicios!!!",
      "to": "Hasta",
      "total": "Total",
      "total_price": "Precio total"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ]
    },
    "transliterate": {
      "rule": {
        "Á": "A",
        "É": "E",
        "Í": "I",
        "Ñ": "N",
        "Ó": "O",
        "Ú": "U",
        "Ü": "U",
        "á": "a",
        "é": "e",
        "í": "i",
        "ñ": "n",
        "ó": "o",
        "ú": "u",
        "ü": "u"
      }
    }
  },
  "login_and_register": {
    "email_validate": {
      "invalid_token": "El token proporcionado no se encuentra o ha expirado, contacto con un administrador.",
      "request_validate_no_authenticated": "Debe de autenticarse para poder validar su correo",
      "request_validate_token": "Se le ha enviado un mensaje de validación al correo <strong>%{email}</strong>, por favor siga las instrucciones del mismo para validar su cuenta.<br><hr><small>Si no aparece en su bandeja de entrada, revise la carpeta de spam.</small>",
      "request_validate_token_error": "Ha ocurrido un error al enviar el correo, inténtelo más tarde, si el error persiste.<br> <strong>Contacte con un administrador</strong>",
      "title": "Validación de Email",
      "validate_error": "Ha ocurrido un error y su cuenta no se ha podido validar, inténtelo más tarde o pongase en contacto con un administrador.",
      "validate_successfully": "<p>Estimado(a) <strong>%{name}</strong></p>Su cuenta fue validada correctamente!!!",
      "validate_token": "<p>Estimado(a) %{name}.</p> Su cuenta fue validada anteriormente, no es necesario realizar la accción."
    },
    "forgot_my_password": {
      "email": "Correo electrónico",
      "helper": "Por favor introduzca su dirección de correo",
      "send_btn": "Enviar",
      "title": "Olvide mi contraseña"
    },
    "login": {
      "auth_message_error": "Usted ya se encuentra autenticado",
      "dont_have_account": "¿No tienes una cuenta aún?",
      "dont_have_account_register": "registrate aqui",
      "email": "Correo electrónico",
      "forgot_my_password": "Si olvidaste tu contraseña puedes",
      "forgot_my_password_recovery": "recuperarla aquí.",
      "login_message_error": "Usuario o Contraseña incorrecto",
      "password": "Contraseña",
      "remember_me": "Recuerdame",
      "title": "Iniciar sesión",
      "username_and_email": "Usuario ó Correo electrónico"
    },
    "password_reset": {
      "can_change_password": "Puede cambiar su contraseña",
      "change_password": "Cambiar contraseña",
      "change_password_invalid": "Solicitud invalida.",
      "change_password_time_expire": "Ha expirado el tiempo para cambiar su contraseña.",
      "email_incorrect": "Error al generar el token temporal para el reestablecimeinto de su contraseña",
      "email_send_error": "No podemos avalar que su correo se encuentre en nuestro sitio",
      "email_send_success": "Se a enviado un correo con los datos para reestabler su contraseña",
      "helper_text": "Si esta viendo este cuadro es porque usted solicito un cambio de contraseña, por motivo de olvido y hemos podido validar que fue usted quien lo solicito, por favor inserte una nueva contraseña y su confirmación.",
      "password_change_error": "Ocurrio un problema al cambiar la contraseña, intente de nuevo.",
      "password_change_successfully": "Su contraseña fue modificada correctamente",
      "password_confirm_field": "Confirmar Contraseña",
      "password_field": "Contraseña",
      "save_btn": "Cambiar contraseña",
      "title": "Solicitud de cambio de contraseña"
    },
    "register": {
      "address": "Dirección",
      "checkbox": "Registrarme e Iniciar sesión",
      "city": "Ciudad",
      "country": "País",
      "email": "Correo electrónico",
      "last_name": "Apellidos",
      "login_btn": "Ir a Iniciar sesión",
      "name": "Nombre",
      "passport": "CI o Pasaporte",
      "password": "Contraseña",
      "password_confirm": "Confirmar contraseña",
      "phone_numbre": "Télefono",
      "send_btn": "Registrarme",
      "title": "Registrarme"
    }
  },
  "mailer": {
    "email_validate": {
      "email_btn": "Validar coreo electrónico",
      "text": "Usted proporcionó un correo electrónico para iniciar sesión en nuestro sistema y es necesario validar que es un correo electrónico real",
      "title": "Estimado(a) %{user}"
    },
    "password_change": {
      "reset_btn": "Recuperar contraseña",
      "text": "Le enviamos este correo porque usted solicito un cambio de contraseña, para realizar dicha acción acceda a la siguiente URL",
      "title": "Estimado(a) %{user}"
    }
  },
  "main": {
    "body": {
      "catalog": {
        "add_to_cart": "Adicionar al carrito",
        "assistance": "Asistencia en línea",
        "cancellation_time": "Tiempo de antelación para cancelar: %{days} días",
        "description": "Descripción",
        "from": "Desde",
        "no_available": "No disponible",
        "other_products": "Otros productos que puedan ser de tu interes",
        "price_tag": "Precio referencial desde: %{price} EUR",
        "rent_btn": "Reservar",
        "reservation_time": "Tiempo de antelación para reservar: %{days} días",
        "title": "Catalogo de productos"
      },
      "read_more": "Leer más..."
    },
    "cookies_box": {
      "button": "Estoy de acuerdo",
      "description": "Almacenamos datos temporalmente para mejorar su experiencia de navegación y recomendarle contenido de interés. Al utilizar nuestros servicios, aceptas dicho seguimiento.",
      "title": "Este sitio web usa cookies"
    },
    "header": {
      "admin": "Administración",
      "check_reservation": {
        "button": "Chequear",
        "email": "Correo electrónico",
        "no_reference": "No. de referencia",
        "title": "Chequear reserva"
      },
      "commercial": "Administración comercial",
      "credit": "Fondo revolvente",
      "home": "Inicio",
      "login": "Acceder",
      "logout": "Salir",
      "profile": "Mi perfil",
      "register": "Registrarse",
      "reservation_request": "Solicitud",
      "shopping_cart": {
        "accept_btn": "Continuar",
        "cancel_btn": "Cancelar",
        "category": "Categoría",
        "destiny": "Destino",
        "drop_date": "Fecha de entrega",
        "pick_date": "Fecha de recogida",
        "price": "Precio",
        "time_elapse": "Tiempo restante",
        "title": "Producto en carrito"
      },
      "transfer": "Traslados",
      "voucher": "Voucher"
    }
  },
  "noscript": {
    "button": "Recargar",
    "text": "Este sitio web usa javascript, revise si su navegador soporta el uso de javascript o si por algún motivo desactivo el uso de los mismos por favor activelo, para poder continuar",
    "title": "Este sitio usa javascript"
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      }
    },
    "format": {
      "delimiter": ".",
      "precision": 3,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "mil millones",
          "million": {
            "one": "millón",
            "other": "millones"
          },
          "quadrillion": "mil billones",
          "thousand": "mil",
          "trillion": {
            "one": "billón",
            "other": "billones"
          },
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n %"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "pre_reservations": {
    "base_price": "Reserva",
    "calculated_price": "Calculando precio...",
    "category": "Categoría",
    "days": "Días de reserva",
    "info": "* Se aplicará tasa de cambio según moneda de pago",
    "initial_gas": "Servicio de habilitado de combustible",
    "price_drop_off": "Entrega en otra oficina",
    "price_hire_point": "Suplemento de oficina",
    "product_description": "Descripción del producto",
    "total_price": "Total",
    "total_price_driver": "Chófer adicional",
    "total_price_insurance": "Relevo de responsabilidad",
    "total_supplement_insurance": "Suplemento de Edad"
  },
  "privacy_policy": {
    "title": "Política de Privacidad y Uso de Cookies"
  },
  "profile": {
    "my_reservations": {
      "last_reservations": "%{count} últimas rentas",
      "price": "Precio",
      "reference": "No. Referencia",
      "status": "Estado",
      "title": "Mis reservaciones",
      "type": "Fecha"
    },
    "notifications": {
      "global_notifications": "Notificaciones globales",
      "my_notifications": "Mis notificaciones",
      "title": "Notificaciones"
    },
    "profile": {
      "address": "Dirección",
      "avatar": "Avatar",
      "avatar_info_edit": "JPG or PNG no mayor de 1MB",
      "city": "Ciudad",
      "country": "País",
      "details": "Detalles de la cuenta",
      "edit_btn": "Editar detalles de la cuenta",
      "email": "Correo electrónico (Para acceder al sitio)",
      "email_invalid": "Validar",
      "email_valid": "Validado",
      "important": "Importante!!!",
      "info": "Para correguir errores en el Nombre, Apellidos o Pasaporte, favor de ponerse en contacto con Comercial a traves del  <a href='mailto:%{email}?subject=Solicitud de Actualización de Datos del usuario: %{user}&body=<b>Datos a actualizar del usuario</b><ul> <li>Nombre:</li> <li>Apellidos:</li> <li>Pasaporte:</li> <li>Motivo de la actualización de los datos:</li> </ul><br><i>Una vez completado el proceso de actualización de los datos recibira un correo de confirmación</i>'>Email de soporte</a>",
      "name": "Nombre (Cómo aparecerá su nombre en el sitio)",
      "passport": "CI o Pasaporte",
      "phone": "Teléfono",
      "surname": "Apellidos",
      "title": "Perfil"
    },
    "profile_save_success": "Perfil modificado",
    "security": {
      "change_password": "Cambiar contraseña",
      "confirm_new_password": "Confirmar nueva contraseña",
      "new_password": "Nueva contraseña",
      "notice_password_change_error": "Contraseña anterior incorrecta",
      "notice_password_change_success": "Contraseña modificada",
      "old_password": "Contraseña anterior",
      "title": "Seguridad"
    }
  },
  "reservation": {
    "main": {
      "action_btn": {
        "finish": "Pagar",
        "next": "Siguiente",
        "prev": "Anterior"
      },
      "steps": {
        "four": "Datos del Pago",
        "one": "Conductor titular",
        "three": "Términos y condiciones",
        "two": "Datos extra"
      },
      "steps_body": {
        "four": {
          "checking_gateway": "Verificando estado de pasarela...",
          "payment_gateway": "Pasarela de pago en línea",
          "proccesing_pay": "Procesando datos...",
          "title": "Método de pago"
        },
        "one": {
          "address": "Dirección",
          "city": "Ciudad",
          "country": "País",
          "email": "Correo electrónico",
          "name": "Nombre(s)",
          "passport": "Pasaporte",
          "surname": "Apellidos"
        },
        "three": {
          "accept_terms": "Acepto los términos y condiciones"
        },
        "two": "Datos extras"
      },
      "title": "Datos de la reserva"
    },
    "side_bar": {
      "active_reservation": "Reserva",
      "add_product": "Adicionar producto",
      "add_product_mobile": "Adicionar",
      "adult": "Adulto",
      "adults": "Adultos",
      "amount": "Cantidad",
      "assistance": "Asistencia",
      "brand": "Marca",
      "cancel_reservation_btn": "Cancel shopping cart",
      "cancel_reservation_mobile_btn": "Cancel",
      "category": "Categoría",
      "children": "Niño",
      "children_age": "Edad del niño",
      "children_info": "Cuando la edad es 0, se considera que es un bebé de meses de nacido.",
      "childrens": "Niños",
      "childrens_age": "Edades de los niños",
      "confirm_cart": "Proceder al pago",
      "confirm_cart_mobile": "Pagar",
      "date": "Fecha",
      "dealer": "Sucursal",
      "delete_product": "Eliminar producto",
      "delete_product_mobile": "Eliminar",
      "departure_from": "Salida desde",
      "destiny": "Destino",
      "dropoff_date": "Fecha de entrega",
      "dropoff_date_hotel": "Fecha de salida",
      "dropoff_place": "Lugar de entrega",
      "dropoff_time": "Hora de entrega",
      "event_type": "Tipo de evento",
      "lang": "Idioma",
      "modality": "Modalidad",
      "model": "Modelo",
      "payment_method": "Método de pago",
      "person_amount": "Personas",
      "pickup_date": "Fecha de recogida",
      "pickup_date_hotel": "Fecha de entrada",
      "pickup_place": "Recogida en",
      "pickup_place_description": "Especifique lugar en que se encuentra ó Punto de recogida",
      "pickup_time": "Hora de recogida",
      "price": "Precio",
      "price_by_person": "Precio x persona",
      "product": "Producto",
      "room_count": "Cantidad de habitaciones",
      "room_for": "Para: %{adult} Adultos y %{childrens} niños",
      "time_elapse": "Tiempo restante",
      "total_price": "Total a pagar",
      "update_product": "Actualizar producto",
      "update_product_mobile": "Actualizar"
    }
  },
  "support": {
    "array": {
      "last_word_connector": " y ",
      "two_words_connector": " y ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%A, %-d de %B de %Y %H:%M:%S %z",
      "long": "%-d de %B de %Y %H:%M",
      "short": "%-d de %b %H:%M"
    },
    "pm": "pm"
  }
});
